import React from "react"


const Furioos = () => {
    return(
        <h2>
            This is Furioos!
        </h2>
    )
}

export default Furioos;