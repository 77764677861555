import React from "react"
import { AppBar, IconButton, List, ListItem, ListItemText, Toolbar, Container } from "@material-ui/core"
import {Link} from 'react-router-dom';
import { Home } from "@material-ui/icons"
import { makeStyles } from "@material-ui/core/styles"

const navLinks = [
    { title: `home`, path:`${process.env.PUBLIC_URL}/home` },
    { title: `furioos`, path: `${process.env.PUBLIC_URL}/furioos` },
    
];


const useStyles = makeStyles({
    navbarDisplayFlex: {
        display: `flex`,
        justifyContent: `space-between`
      },
    navDisplayFlex: {
      display: `flex`,
      justifyContent: `space-between`
    },
    linkText: {
      textDecoration: `none`,
      textTransform: `uppercase`,
      color: `white`
    }
  });

const Header = () => {
const classes = useStyles();
  return (
    <AppBar position="static">
      <Toolbar>
      <Container maxWidth="md"  className={classes.navbarDisplayFlex}>

        <IconButton edge="start" color="inherit" aria-label="home">
          <Home fontSize="large" />
        </IconButton>
        <List 
        component="nav"
        aria-labelledby="main navigation"
        className={classes.navDisplayFlex} 
        >
            
            {navLinks.map(({ title, path }) => (
            <Link to={path} key={title} className={classes.linkText}>
                <ListItem button>
                <ListItemText primary={title} />
                </ListItem>
            </Link>
            ))}
        </List>
        </Container>
      </Toolbar>
    </AppBar>
  )
}
export default Header