import React from 'react';
import logo from './logo.svg';
import './App.css';
import { Redirect, Route,  BrowserRouter as Router, Switch } from 'react-router-dom';
import Home from './pages/Home';
import Header from './components/Header';
import Furioos from './pages/Furioos';

function App() {
  return (
    <React.Fragment>
      <Header/>
      <Router basename={'/furioos-try'}>
      <Switch>
        <Route exact path={`${process.env.PUBLIC_URL}/`}>
          <Redirect to={`${process.env.PUBLIC_URL}/home`}/>
        </Route>
        <Route path={`${process.env.PUBLIC_URL}/home`} component={Home}/>
        <Route path={`${process.env.PUBLIC_URL}/furioos`} component={Furioos}/>
      </Switch>
    </Router>
    </React.Fragment>
    
    
  );
}

export default App;
